import ServiceApi from '../ServiceApi';
/** Сервис для работы со страницей загрузки шаблонов */
export default class ServiceReconciliationPreset {
    /**
     * Определить поля автоматически
     * @param {String} fileId - идентификатор пресета
     * @param {Function} actionAfter - событие, сработающее после получения данных
     */
    static async setAuto(fileId = '', actionAfter = () => {}) {
        const postData = {file_id: String(fileId)};
        try {
            const res = await ServiceApi.post('reconciliation', '/preset/default/auto-set', postData);
            const {data = {}} = res;
            const {data: dataSetAuto = {}} = data;
            actionAfter(dataSetAuto);
        }
        catch(error) {
            console.log(error);
        }
    }
}