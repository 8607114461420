<template>
    <div class="reconciliation">
        <div
            v-show="isLoading"
            class="loading"
        ><BasePreloader :size="80" /></div>
        <stepper
            :stepperList="stepperList"
            class="reconciliation__stepper"
            @onClickStep="onClickStep"
        />
        <div class="reconciliation__title">
            {{stepperItemTitle}}
        </div>

        <!-- <div class="steps">
            <BaseButton
                view="simple"
                class="steps__cancel"
                @click="$router.push({name: 'reconciliationStart'})"
            >
                Отменить
            </BaseButton>

            <AppSteps
                :steps="steps"
                :activeStepId="activeStepId"
                @checkStep="onCheckStep"
            />

            <BaseButton
                view="secondary"
                class="steps__continue"
                :disabled="isButtonDisabled"
                @click="onContinue"
            >
                {{ buttonText }}
            </BaseButton>
        </div> -->
        <div class="reconciliation__content">
            <div class="reconciliation__content_left">
                <SettingUpTemplate
                    v-if="activeStepId === 1 && !presetFileId"
                    class="reconciliation__attach"
                    :clientId="clientData.id"
                    :templateName="templateName"
                    :fileData="fileData"
                    :clientList="clientList"
                    @changeTemplate="templateName = $event"
                    @addFile="fileData = $event"
                    @clearFile="fileData = null"
                    @checkClient="onCheckClient"
                />

                <SettingUpDate
                    v-if="activeStepId === 1 && presetFileId"
                    class="reconciliation__attach"
                    :clientId="clientData.id"
                    :templateName="templateName"
                    :clientList="clientList"
                    @changeTemplate="templateName = $event"
                    @checkClient="onCheckClient"
                />

                <AppDefinition
                    v-if="activeStepId === 2"
                    style="overflow: hidden;"
                    :templateName="templateName"
                    :clientName="clientData.value"
                    :fileName="fileData.name"
                    :columns="definitionColumns"
                    :rows="definitionRows"
                    @allReset="onAllReset"
                    @check="onSelect"
                    @onSetAuto="onSetAuto"
                />
                <div
                    :class="actionClass"
                    class="reconciliation__action"
                >
                    <div>
                        <BaseButton
                            view="secondary"
                            form="square"
                            class="steps__continue"
                            :disabled="isButtonDisabled"
                            @click="onContinue"
                        >
                            {{ buttonText }}
                        </BaseButton>
                    </div>
                </div>
            </div>
            <div
                v-if="activeStepId === 2"
                class="reconciliation__content_right"
            >
                <fields-require
                    ref="fieldsRequire"
                    :fields="dropdownOptionsRequired"
                    :fieldsSelected="dropdownOptionsRequiredSelectedArr"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Stepper from '../components/Stepper/Stepper';
// import AppSteps from "@/components/Reconciliation/Steps/Steps"
import BaseButton from "@/components/Base/BaseButton"
import SettingUpTemplate from "@/components/Reconciliation/SettingUpTemplate"
import SettingUpDate from "@/components/Reconciliation/SettingUpDate"
import AppDefinition from "@/components/Reconciliation/Definition/Definition"
import BasePreloader from "@/components/Base/BasePreloader"
import ServiceReconciliationDownload from "@/services/ServicesReconciliation/ServiceReconciliationDownload";
import ServiceApi from '@/services/ServiceApi';
import FieldsRequire from '@/components/Reconciliation/Definition/FieldsRequire';
import ServiceReconciliationPreset from '@/services/ServicesReconciliation/ServiceReconciliationPreset';
export default {
	name: "PageReconciliation",
	components: {
        Stepper,
		// AppSteps,
		BaseButton,
		SettingUpTemplate,
		AppDefinition,
		BasePreloader,
        SettingUpDate,
        FieldsRequire
	},
    /**
     * Входные данные компонента
     * @property {String} presetFileId
     * @property {String} presetId
     */
    props: {
        presetFileId: {
            type: String,
            default: ''
        },
        presetId: {
            type: String,
            default: ''
        },
    },
	data() {
		return {
			activeStepId: 1,
			clientList: [],
			templateName: "",
			clientData: { value: '', id: '' },
			fileData: null,
            fileId:null,
			definitionColumns: [
				{
					prop: 'choose',
					label: 'Выберите значение',
					isSlot: true
				},
				{
					prop: 'titles',
					label: 'Заголовки из файла',
					columnStyles: {
						fontWeight: 700
					}
				},
				{
					prop: 'example',
					label: 'Пример данных из файла',
					columnStyles: {
						color: '#ADB0BB',
						fontWeight: 700,
						flexGrow: 1
					}
				},
			],
			definitionRows: [
				// {
				// 	id: 1,
				// 	choose: {
				// 		selected: 1,
				// 		options: [
				// 			{id: 1, value: 'значение 1'},
				// 			{id: 2, value: 'значение 2'},
				// 			{id: 3, value: 'значение 3'},
				// 		]
				// 	},
				// 	titles: "Уникальный ID конструкции",
				// 	example: "MS34204232",
				// 	example1: "OOHDESK",
				// 	example2: "MS34204232",
				// },
			],

			isLoading: false,
            dropdownOptions: [],
            dropdownOptionsRequiredSelected: {}
		}
	},
	computed: {
        ...mapState({
            fieldsIsSelectedAll: state => state.reconciliation.fieldsIsSelectedAll
        }),
        dropdownOptionsRequired() {
            const dropdownOptionsRequired = this.dropdownOptions.filter(dropdownOption => {
                const {required = false, requiredGroup = '', requiredType = ''} = dropdownOption;
                return Boolean(required) || String(requiredGroup) !== '' || String(requiredType) !== '';
            });
            return dropdownOptionsRequired;
        },
        dropdownOptionsRequiredSelectedArr() {
            const optionsFiltered = Object.values(this.dropdownOptionsRequiredSelected).filter((option = {}) => {
                const {required = '', requiredGroup = '', requiredType = ''} = option;
                return Boolean(required) || String(requiredGroup) !== '' || String(requiredType) !== '';
            })
            const selected = optionsFiltered.reduce((selected, option) => {
                const {id = '-1'} = option;
                if (!selected.has(id)) {
                    selected.set(id, option);
                }
                return selected;
            }, new Map());
            return Array.from(selected.values());
        },
        dropdownOptionsNoSelected() {
            const dropdownOptionsIdsSelected = Object.values(this.dropdownOptionsRequiredSelected).map((option = {}) => {
                const {id = ''} = option;
                return String(id);
            });
            const dropdownOptionsNoSelected = this.dropdownOptions.filter(option => {
                const {id = ''} = option;
                return !dropdownOptionsIdsSelected.includes(String(id));
            });
            return dropdownOptionsNoSelected;
        },
        stepperList() {
            const stepperList = [
                {
                    id: 1,
                    idNext: 2,
                    name: 'Шаг 1',
                    title: 'Настройка шаблона',
                    status: this.getStatusStep(1),
                    isNext: true,
                    canComplited: this.templateName != "" && (this.fileData != null || this.presetFileId)
                },
                {
                    id: 2,
                    name: 'Шаг 2',
                    title: 'Разметка данных из Excel',
                    status: this.getStatusStep(2),
                    isNext: false,
                    canComplited: this.fieldsIsSelectedAll
                },
            ];
            return stepperList;
        },
        stepperItemTitle() {
            const step = this.stepperList.find(step => parseInt(step.id) === parseInt(this.activeStepId)) || {};
            const {title = ''} = step;
            return title;
        },
		steps() {
			return [
				{
					id: 1,
					title: "Настройка шаблона",
					canNext: this.templateName != "" && (this.fileData != null || this.presetFileId)
				},
				{
					id: 2,
					title: "Определение данных",
					canNext: true
				},
			]
		},
		isButtonDisabled() {
			// const step = this.steps.find(step => step.id === this.activeStepId)
			// if (step) { return !step.canNext }

			// return true
            const step = this.stepperList.find(step => parseInt(step.id) === parseInt(this.activeStepId)) || {};
            const {canComplited = false} = step;
            return !canComplited;
		},
		buttonText() {
			if (this.activeStepId === 1) {
				return 'Продолжить'
			} else if (this.activeStepId === 2) {
				return 'Сохранить'
			} else {
				return 'Продолжить'
			}
		},
        actionClass() {
            const actionClassBase = 'reconciliation__action';
            let actionClass = '';
            switch(this.activeStepId) {
                case 1:
                    actionClass = `${actionClassBase}_attach`;
                    break;
                case 2:
                    actionClass = `${actionClassBase}_definition`;
                    break;
                default:
                    break;
            }
            return actionClass;
        }
	},
    beforeMount() {
        if(this.presetFileId){
            this.fileData = {};
        }
        if(this.presetId && this.activeStepId === 1){
            this.activeStepId = 2;
        }
    },
    created() {

		// this.fetchClients()
	},
	methods: {
        onSetAuto() {
            ServiceReconciliationPreset.setAuto(this.fileId, (dataSetAuto) => {
                Object.keys(dataSetAuto).forEach(optionId => {
                    const rowId = dataSetAuto[optionId] || '';
                    const rowData = this.definitionRows.find(row => {
                        const {id = ''} = row;
                        return String(id) === String(rowId);
                    });
                    const optionData = this.dropdownOptions.find(option => {
                        const {id = ''} = option;
                        return String(id) === String(optionId);
                    });
                    const data = {optionData, rowData};
                    this.onSelect(data);
                });
            });
        },
        onClickStep(id = -1) {
            if (this.activeStepId > id)
                this.activeStepId = id;
            else if (this.activeStepId < id && !this.isButtonDisabled) {
                const step = this.stepperList.find(step => parseInt(step.id) === parseInt(id)) || {};
                const {canComplited = false} = step;
                if (canComplited)
                   this.activeStepId = id;
            }
        },
        getStatusStep(id = -1) {
            let status = 'uncompleted';
            if (parseInt(id) === parseInt(this.activeStepId))
                status = 'current';
            else if (parseInt(id) < parseInt(this.activeStepId))
                status = 'completed';
            return status;
        },
		onCheckClient(event) {
			this.clientData = event
		},
		onAllReset() {
			this.definitionRows.forEach(item => {
				item.choose.selected = null
			})
            this.dropdownOptionsRequiredSelected = {};
		},
		onSelect(event) {
			const id = event.rowData.id
			const currentRow = this.definitionRows.find(item => item.id == id)

			if (event.optionData.id === 'reset') {
				currentRow.choose.selected = null
                currentRow.choose.isCustomPreview = false;
                currentRow.choose.previewColor = '';
			} else {
				currentRow.choose.selected = event.optionData.id
                currentRow.choose.isCustomPreview = true;
                currentRow.choose.previewColor = '#EAF8F1';
			}
            const {choose = {}} = currentRow;
            const {selected = ''} = choose;
            const dropdownOption = this.dropdownOptions.find(dropdownOption => {
                const {id = ''} = dropdownOption;
                return String(id) === String(selected);
            });
            this.dropdownOptionsRequiredSelected = {...this.dropdownOptionsRequiredSelected, [id]: dropdownOption};

		},
		/**
		 * Перейдет на выбранный шаг если это возможно
		 */
		onCheckStep(stepData) {
			this.activeStepId = stepData.id
		},
		/**
		 * Перейти на следующий шаг
		 */
		onContinue() {
			// const stepIds = this.steps.map(item => item.id)
			// const index = stepIds.findIndex(id => id === this.activeStepId)
			// this.activeStepId = stepIds[index + 1]

			this.activeStepId = this.activeStepId += 1
		},
		/**
		 * Загружаю массив клиентов
		 */
		async fetchClients() {
			try {
				this.isLoading = true
                const responce = await ServiceApi.post('reconciliation', '/preset/default/operators-list');
				// const responce = await this.$axios('https://reconciliation.oohdesk.ru/preset/default/operators-list')
				this.clientList = responce.data.data
			} catch (err) {
				console.log(err);
			} finally {
				this.isLoading = false
			}
		},
		/**
		 * При подтверждении первого шага
		 */
		async fetchTemplateStep() {
			try {
				this.isLoading = true
                let fileId;
                if(this.presetFileId){
                    let reconciliation = ServiceReconciliationDownload.checkFile(this.presetFileId);
                    let promise = await Promise.resolve(reconciliation);
                    fileId = Number(this.presetFileId);//reconciliation.fileId;
                    this.fileData = {name: promise.fileName, id: fileId};
                }else {
                    let formData = new FormData();
                    formData.append('file', this.fileData);
                    const responce = await ServiceApi.post('reconciliation', '/upload/default/upload-file', formData, { headers: {'Content-Type': 'multipart/form-data'}});
                    // const responce = await this.$axios.post('https://reconciliation.oohdesk.ru/upload/default/upload-file', formData, { headers: {'Content-Type': 'multipart/form-data'}})
                    fileId = responce.data.data.fileId
                    this.$set(this.fileData, 'id', fileId)
                }
                this.fileId = fileId;
                const res2 = await ServiceApi.post('reconciliation', '/preset/default/create', {fileId, presetId: this.presetId});
				// const res2 = await this.$axios.post('https://reconciliation.oohdesk.ru/preset/default/create', {fileId})
                if(Object.keys(res2.data.data.selected).length){
                    this.dropdownOptionsRequiredSelected = res2.data.data.selected.columns;
                    this.templateName = res2.data.data.selected.name;
                }

				/**
				 * Адаптирую опции дропдауна
				 */
				const dropdownOptions = res2.data.data.dropdown.map(item => ({id: item.value, value: item.label}))
				dropdownOptions.unshift({id: 'reset', value: 'Игнорировать'})
                const {data: res2Data = {}} = res2;
                const {data = {}} = res2Data;
                const {dropdown = []} = data;
                this.dropdownOptions = dropdown.map(item => {
                    const {value = '', label = '', required = false, requiredGroup = '', requiredType = ''} = item;
                    const itemAdapted = {
                        id: String(value),
                        value: String(label),
                        required: Boolean(required),
                        requiredGroup: String(requiredGroup),
                        requiredType: String(requiredType)
                    };
                    return itemAdapted;
                });
				const rows = res2.data.data.rows

				/* Количество строк с примерами */
				const examplesCounter = rows[0].fileData.length - 1
				/* Формирую колонки */
				for (let i = 1; i <= examplesCounter; i++) {
					const propName = 'example' + i
					const columnData = this.definitionColumns.find(item => item.prop === propName)

					if (!columnData) {
						this.definitionColumns.push({
							prop: 'example' + i,
							label: '',
							columnStyles: {
								color: '#ADB0BB',
								fontWeight: 700,
								flexGrow: 1
							}
						})
					}
				}

				/**
				 * Адаптирую строки таблицы
				 */
				const definitionRows = []
				rows.forEach(item => {
					const exampleData = {}

					for (let i = 1; i <= examplesCounter; i++) {
						exampleData[`example${i}`] = item.fileData[i]
					}

                    let newItem = {
                        id: item.column,
                        choose: {
                            selected: item.dropdown,
                            options: []
                        },
                        titles: item.fileHeaders,
                        example: item.fileData[0],
                        ...exampleData
                    };
                    if(item.dropdown !== null){
                        newItem.choose.isCustomPreview = true;
                        newItem.choose.previewColor = '#EAF8F1';
                    }
					definitionRows.push(newItem)

					this.definitionRows = definitionRows
				})

				this.definitionRows.forEach(item => {
					item.choose.options = dropdownOptions
				})
			} catch (err) {
				console.log(err);
			} finally {
				this.isLoading = false
			}
		},
		/**
		 * При подтверждении второго шага
		 */
		async fetchLastStep() {
			const options = {}

			this.definitionRows.forEach(item => {
				if (item.choose.selected) {
					options[item.choose.selected] = item.id
				}
			})

			const body = {
				clientId: this.clientData.id,
				templateName: this.templateName,
				fileId: this.fileData.id,
				options: options,
                presetId: this.presetId
			}

			try {
				this.isLoading = true
                await ServiceApi.post('reconciliation', '/preset/default/save', body);
				// await this.$axios.post('https://reconciliation.oohdesk.ru/preset/default/save', body)
			} catch(err) {
				console.log(err);
			} finally {
				this.isLoading = false
			}
		}
	},
	watch: {
		async activeStepId(to, from) {
			// console.log(to, from);
			if (to === 2 && from === 1) {
				this.fetchTemplateStep()
			} else if (to === 3 && from === 2) {
				await this.fetchLastStep()
				this.$router.push({path: '/reconciliation-download?file=' + this.fileId})
			}
		},
        dropdownOptionsNoSelected() {
            this.$nextTick(() => {
                this.definitionRows = this.definitionRows.map(row => {
                    const {choose = {}} = row;
                    const {options = [], selected = null} = choose;
                    let newOptions = [{id: "reset", value: "Игнорировать"}];
                    if (selected !== null) {
                        const optionSelected = options.find(option => {
                            const {id = ''} = option;
                            return String(id) === String(selected);
                        }) || {};
                        newOptions.push(optionSelected);
                    }
                    newOptions.push(...this.dropdownOptionsNoSelected);
                    const newRow = {
                        ...row,
                        choose: {
                            ...row.choose,
                            options: newOptions
                        }
                    };
                    return newRow;
                });
            })
        }
	}
}
</script>

<style lang="scss" scoped>
.reconciliation {
	display: flex;
    flex-direction: column;
	height: 100%;
    overflow: auto;
    &__content {
        overflow: hidden;
        display: flex;
        &_left {
            width: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }
        &_right {
            margin-left: 60px;
        }
    }
    &__title {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: bold;
    }
    &__action {
        display: flex;
        justify-content: flex-end;
        &_attach {
            width: 340px;
        }
    }
    &__stepper {
        margin-bottom: 30px;
    }
	.loading {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
    }

	&__attach {
		width: 340px;
	}

	.steps {
		&__cancel {
			margin-bottom: 30px;
		}
		&__continue {
			// border-radius: 1000px;
			margin-top: 30px;
		}
	}
}
</style>
