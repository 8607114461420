<template>
    <div class="stepper">
        <stepper-item
            v-for="(stepperItem, stepperItemIndex) in stepperList"
            :key="stepperItemIndex"
            :stepperItem="stepperItem"
            @onClickStep="onClickStep" 
        />
    </div>
</template>

<script>
import StepperItem from './StepperItem';
export default {
    name: 'Stepper',
    components: {StepperItem},
    /**
     * Входные данные компонента
     * @property {Array} stepperList - список шагов
     */
    props: {
        stepperList: {
            type: Array,
            default: () => ([])
        }
    },
    methods: {
        /**
         * Событие клика по шагу
         * @param {Number} id - идентификатор шага
         */
        onClickStep(id = -1) {
            this.$emit('onClickStep', parseInt(id));
        }
    }
}
</script>

<style lang="scss" scoped>
    .stepper {
        display: flex;
    }
</style>