<template>
    <div 
        class="fields-require-item"
        :class="classItem"
    >
        <div 
            :class="classItemValue"
            class="fields-require-item__value"
        >
            {{fieldValue}}
        </div>
        <div 
            v-if="isSelected"
            class="fields-require-item__status"
        >
            <div class="fields-require-item__circle">
                <base-icon 
                    icon="icon-tick"
                    size="sm"
                    color="#fff"
                    class="fields-require-item__circle-icon"
                />
            </div>
        </div>
    </div>
</template>

<script>
/** Компонент для элемента обязательных полей */
import BaseIcon from "@/components/Base/BaseIcon";
export default {
    name: 'FieldsRequireItem',
    components: {BaseIcon},
    /**
     * Входные данные компонента
     * @property {Object} field - поле
     * @property {Boolean} isSelected - выбрано ли поле
     */
    props: {
        field: {
            type: Object,
            default: () => ({})
        },
        isSelected: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        /**
         * Значение поля
         * @returns {String}
         */
        fieldValue() {
            const {value = ''} = this.field;
            return String(value);
        },
        /**
         * Класс для поля
         * @returns {String}
         */
        classItem() {
            const classItem = this.getClass('fields-require-item');
            return classItem;
        },
        /**
         * Класс для значения поля
         * @returns {String}
         */
        classItemValue() {
            const classItemValue = this.getClass('fields-require-item__value');
            return classItemValue;
        }
    },
    methods: {
        /**
         * Получить класс для элемента
         * @param {String} classBase - название базового класс, к которому добавится модификатор
         */
        getClass(classBase = '') {
            let classNew = '';
            if (this.isSelected)
                classNew = `${classBase}_selected`;
            else
                classNew = `${classBase}_no-selected`;
            return classNew;
        }
    }
}
</script>

<style lang="scss" scoped>
    .fields-require-item {
        display: flex;
        justify-content: space-between;
        padding: 9px 10px 9px 15px;
        margin-bottom: 4px;
        border-radius: 4px;
        &_selected {
            background: #EAF8F1;
        }
        &_no-selected {
            background: #FFFFFF;
            border: 1px solid #F4F5F6;
        }
        &__value {
            &_selected {
                color: #000000;
            }
            &_no-selected {
                color: #F84967;
            }
        }
        &__status {
            display: flex;
            align-items: center;
        }
        &__circle {
            border-radius: 100%;
            width: 14px;
            height: 14px;
            background: #6EC87A;
            margin-left: 5px;
            &-icon {
                padding-left: 2px;
            }
        }
    }
</style>