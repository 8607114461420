<template>
	<div class="panel">
		<div class="descriptions">
			<div
				v-for="(desc, index) of descriptions"
				:key="index"
				v-show="desc.title"
				:class="desc.classes"
				class="description"
			>
				<BaseIcon
					v-if="desc.icon"
					:name="desc.icon"
					class="description__icon"
				/>
				<span class="description__title">{{ desc.title }}</span>
			</div>
		</div>

		<div class="panel__buttons">
			<!-- <button
				class="btn-reset"
				@click="onReset"
			>
				Сбросить поля
			</button> -->
            <base-button
                form="oval"
                view="primary"
                class="panel__buttons_auto"
                @click="onSetAuto"
            >
                Определить автоматически
            </base-button>
            <base-button
                form="oval"
                view="primary"
                @click="onReset"
            >
                Сбросить поля
            </base-button>
		</div>
	</div>
</template>

<script>
import BaseIcon from "@/components/Base/BaseSvgIcon"
import BaseButton from "@/components/Base/BaseButton"
export default {
	name: "Panel",
	components: {
		BaseIcon,
        BaseButton
	},
	props: {
		template: {
			type: String,
			default: ""
		},
		clientName: {
			type: String,
			default: ""
		},
		fileName: {
			type: String,
			default: ""
		},
	},
	computed: {
		descriptions() {
			return [
				{
					title: this.template,
				},
				{
					icon: 'person',
					title: this.clientName,
					classes: ['unique'],
				},
				{
					icon: 'file',
					title: this.fileName,
					classes: ['unique'],
				},
			]
		}
	},
	methods: {
		onReset() {
			this.$emit('reset')
		},
        onSetAuto() {
            this.$emit('onSetAuto');
        }
	}
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;

.panel {
	.unique {
		color: $assistant-color;
	}

	.descriptions {
		// padding-bottom: 15px;
		// border-bottom: 1px solid $primary-disabled-color;
		display: flex;
		align-items: center;

		.description {
			font-weight: 500;
			display: flex;
			align-items: center;
			white-space: nowrap;

			&:not(:first-child) {
				margin-left: 30px;
			}

			&__icon {
				flex-shrink: 0;
				width: 12px;
				margin-right: 10px;
			}

			// &__title {}
		}
	}

	&__buttons {
		// margin-top: 30px;
        display: flex;
        &_auto {
            margin-right: 7px;
        }
        &_blue {
            color: #4A92F6;
        }
	}

	.btn-reset {
		color: $active-color;
		font-weight: 500;
	}
}
</style>