<template>
    <div class="fields-require">
        <div class="fields-require__title">
            Обязательные поля для разметки
        </div>
        <div class="fields-require__list">
            <fields-require-item
                v-for="(field, fieldKey) in [...fieldsView]"
                :key="fieldKey"
                :field="field"
                :isSelected="getFieldIsSelected(field)"
            />
        </div>
        <!-- <div class="fields-require__description">
            Кнопка «Сохранить» активируется после разметки всех обязательных полей из Excel.
        </div> -->
    </div>
</template>

<script>
/** Компонент для обязательных полей в загрузке шаблона */
import FieldsRequireItem from './FieldsRequireItem';
import { mapMutations } from 'vuex';
export default {
    name: 'FieldsRequire',
    components: {
        FieldsRequireItem
    },
    /**
     * Входные данные компонента
     * @property {Array} fields - поля
     * @property {Array} fieldsSelected - выбранные поля
     */
    props: {
        fields: {
            type: Array,
            default: () => ([])
        },
        fieldsSelected: {
            type: Array,
            default: () => ([])
        }
    },
    computed: {
        /**
         * Базовые поля
         * @returns {Array}
         */
        fieldsBase() {
            return this.fieldsFilteringRequired(this.fields, true);
        },
        /**
         * Поля с группой или типом
         * @returns {Array}
         */
        fieldsNotBase() {
            return this.fieldsFilteringRequired(this.fields, false);
        },
        /**
         * Выбранные поля с группой или типом
         */
        fieldsSelectedNotBase() {
            return this.fieldsFilteringRequired(this.fieldsSelected, false);
        },
        /**
         * Поля сгруппированные по группе
         * @returns {Map}
         */
        fieldsGroup() {
            return this.fieldsGrouping('requiredGroup');
        },
        /**
         * Поля сгруппированные по типу
         * @returns {Map}
         */
        fieldsType() {
            return this.fieldsGrouping('requiredType');
        },
        /**
         * Идентификаторы полей с группой или типом
         * @returns {Array}
         */
        fieldsNotBaseIds() {
            return this.fieldsMappingIds(this.fieldsNotBase);
        },
        /**
         * Идентификаторы выбранных полей
         * @returns {Array}
         */
        fieldsSelectedIds() {
            return this.fieldsMappingIds(this.fieldsSelected);
        },
        /**
         * Есть ли среди выбранных хотя бы одно не базовое поле
         * @returns {Boolean}
         */
        isFieldsNotBaseSelected() {
            const index = this.fieldsSelectedIds.findIndex(id => this.fieldsNotBaseIds.includes(String(id)));
            return index !== -1;
        },
        /**
         * Список обязательных полей для рендера
         * @returns {Array}
         */
        fieldsView() {
            let fieldsView = [...this.fieldsBase]
            if(!this.isFieldsNotBaseSelected)
                fieldsView.push({id: 'empty', value: 'Цена'});
            else
                fieldsView.push(...this.fieldsViewNotBase);
            return fieldsView;
        },
        /**
         * Список полей с группой или типом для рендера
         * @returns {Array}
         */
        fieldsViewNotBase() {
            const fieldsViewNotBase = this.fieldsSelectedNotBase.reduce((fieldsViewNotBase, field) => {
                const {requiredType = ''} = field;
                const index = fieldsViewNotBase.findIndex(fieldView => {
                    const {requiredType: requiredTypeFieldView} = fieldView;
                    return String(requiredTypeFieldView) === String(requiredType);
                });
                if (index === -1) {
                    const fieldsType = this.fieldsType.get(requiredType) || [];
                    const fieldsTypeByGroup = fieldsType.reduce((fieldsTypeByGroup, fieldType) => {
                        const {requiredGroup = '', id = '', value = ''} = fieldType;
                        const indexDouble = fieldsTypeByGroup.findIndex(fieldTypeByGroup => {
                            const {requiredGroup: requiredGroupCurrent = ''} = fieldTypeByGroup;
                            return String(requiredGroupCurrent) === String(requiredGroup);
                        });
                        if (indexDouble !== -1) {
                            const {id: idDouble = '-1', value: valueDouble = '', requiredGroup = ''} = fieldsTypeByGroup[indexDouble] || {};
                            const fieldNew = {
                                id: `${id}_${idDouble}`, 
                                value: `${value} или ${valueDouble}`,
                                items: [fieldsTypeByGroup[indexDouble], fieldType],
                                requiredGroup
                            };
                            fieldsTypeByGroup.splice(indexDouble, 1, fieldNew);
                        }
                        else
                            fieldsTypeByGroup.push(fieldType);
                        return fieldsTypeByGroup;
                    }, []);
                    fieldsViewNotBase.push(...fieldsTypeByGroup);
                }
                return fieldsViewNotBase;
            }, []);
            return fieldsViewNotBase;
        },
        /**
         * Выбраны ли все поля
         * @returns {Boolean}
         */
        fieldsIsSelectedAll() {
            const fieldsSelected = this.fieldsView.filter(field => this.getFieldIsSelected(field));
            return fieldsSelected.length === this.fieldsView.length;
        }
    },
    watch: {
        /** Следим за выбранными полями и меняем условие выбраны ли все поля */
        fieldsSelected() {
            this.setFieldsIsSelectedAll(this.fieldsIsSelectedAll);
        }
    },
    methods: {
        /** Проксируем мутации из стора */
        ...mapMutations('reconciliation', ['setFieldsIsSelectedAll']),
        /**
         * Группировака полей
         * @param {String} keyGrouping - ключ для группировки
         * @returns {Map}
         */
        fieldsGrouping(keyGrouping = '') {
            const fieldsGrouping = this.fields.reduce((fieldsGrouping, field) => {
                const valueGrouping = field[keyGrouping] || '';
                let fieldsGroupingNew = fieldsGrouping;
                if (valueGrouping !== '') {
                    const items = fieldsGrouping.get(valueGrouping) || [];
                    fieldsGroupingNew.set(valueGrouping, [...items, field]);
                }
                return fieldsGroupingNew;
            }, new Map());
            return fieldsGrouping;
        },
        /**
         * Фильтрация полей по ключу required
         * @param {Array} fields - поля
         * @param {Boolean} requiredValue - значение поля required
         * @returns {Array}
         */
        fieldsFilteringRequired(fields = [], requiredValue = false) {
            const fieldsFiltered = fields.filter(field => {
                const {required = false} = field;
                return Boolean(required) === Boolean(requiredValue);
            });
            return fieldsFiltered;
        },
        /**
         * Смапить поля по полю id
         * @param {Array} fields - поля
         * @returns {Array}
         */
        fieldsMappingIds(fields = []) {
            const fieldsMappingIds = fields.map(field => {
                const {id = ''} = field;
                return String(id);
            });
            return fieldsMappingIds;
        },
        /**
         * Выбрано ли поле
         * @param {Object} field - полу
         * @returns {Boolean}
         */
        getFieldIsSelected(field) {
            let isSelected = false;
            const {id = '', items = []} = field;
            if (items.length !== 0) {
                const itemsIds = this.fieldsMappingIds(items);
                const index = itemsIds.findIndex(itemId => this.fieldsSelectedIds.includes(String(itemId)));
                isSelected = index !== -1;
            }
            else
                isSelected = this.fieldsSelectedIds.includes(String(id));
            return isSelected;
        }
    }
}
</script>

<style lang="scss" scoped>
    .fields-require {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 230px;
        &__title {
            font-size: 16px;
            font-weight: bold;
        }
        &__description {
            margin-top: 23px;
            font-size: 12px;
            color: #ADB0BB;
        }
        &__list {
            margin-top: 26px;
            height: 100%;
            overflow: auto;
        }
    }
</style>