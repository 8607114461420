<template>
    <div class="stepper__item-container">
        <div 
            :class="stepperItemClass"
            class="stepper__item"
            @click="onClickStep"
        >
            <div class="stepper__item-name">
                {{stepperItemName}}
            </div>
            <div
                :class="stepperItemStatusClass" 
                class="stepper__item-status"
            >
                <base-icon 
                    v-if="stepperItemStatus === statuses[0]" 
                    icon="icon-tick"
                    size="sm"
                    color="#fff"
                    class="stepper__item-status-icon" 
                />
            </div>
        </div>
        <base-icon 
            v-if="stepperItemIsNext" 
            icon="icon-next"
            size="sm"
            color="#DCE1E8"
            class="stepper__item-next-icon"
            @click="onClickStepNext"
        />
    </div>
</template>

<script>
import BaseIcon from "../Base/BaseIcon";
export default {
    name: 'StepperItem',
    components: {BaseIcon},
    /**
     * Входные данные компонента
     * @property {Object} stepperItem - элемент шага
     */
    props: {
        stepperItem: {
            type: Object,
            default: () => ({})
        }
    },
    /**
     * Данные компонента
     * @property {Array} statuses - статусы шага
     */
    data: () => ({
        statuses: ['completed', 'current', 'uncompleted']
    }),
    computed: {
        /**
         * Идентификатор шага
         * @returns {Number}
         */
        stepperItemId() {
            const {id = -1} = this.stepperItem;
            return parseInt(id);
        },
        /**
         * Идентификатор следующего шага
         * @returns {Number}
         */
        stepperItemIdNext() {
            const {idNext = -1} = this.stepperItem;
            return parseInt(idNext);
        },
        /**
         * Название шага
         * @returns {String}
         */
        stepperItemName() {
            const {name = ''} = this.stepperItem;
            return String(name);
        },
        /**
         * Статус шага ('completed'|'current'|'uncompleted')
         * @returns {String}
         */
        stepperItemStatus() {
            let {status = 'uncompleted'} = this.stepperItem;
            status = this.statuses.includes(status) ? status : 'uncompleted';
            return String(status);
        },
        /**
         * Есть ли следующий шаг
         * @returns {Boolean}
         */
        stepperItemIsNext() {
            const {isNext = false} = this.stepperItem;
            return Boolean(isNext);
        },
        /**
         * Класс для шага
         * @returns {String}
         */
        stepperItemClass() {
            const stepperItemClassBase = 'stepper__item';
            const stepperItemClass = this.getClass(stepperItemClassBase);
            return String(stepperItemClass);
        },
        /**
         * Класс для статуса шага
         * @returns {String}
         */
        stepperItemStatusClass() {
            const stepperItemStatusClassBase = 'stepper__item-status';
            const stepperItemStatusClass = this.getClass(stepperItemStatusClassBase);
            return String(stepperItemStatusClass);
        }
    },
    methods: {
        /**
         * Получить класс в зависимости от статуса
         * @returns {String}
         */
        getClass(classBase = '') {
            let className = '';
            switch(this.stepperItemStatus) {
                case this.statuses[0]:
                    className = `${classBase}_completed`;
                    break;
                case this.statuses[1]:
                    className = `${classBase}_current`;
                    break;
                case this.statuses[2]:
                    className = `${classBase}_uncompleted`;
                    break;
                default:
                    break;
            }
            return className;
        },
        /** Событие клика по шагу */
        onClickStep() {
            this.$emit('onClickStep', this.stepperItemId);
        },
        /** Событие клика по иконке перехода к следующему шагу */
        onClickStepNext() {
            this.$emit('onClickStepNext', this.stepperItemIdNext)
        }
    }
}
</script>

<style lang="scss" scoped>
    .stepper__item {
        display: flex;
        align-items: center;
        padding: 12px 10px 12px 20px;
        margin-right: 10px;
        border-radius: 50px;
        cursor: pointer;
        &-container {
            display: flex;
            align-items: center;
        }
        &_completed {
            background: #EAF8F1;
        }
        &_current {
            background: #FFF8E8;
        }
        &_uncompleted {
            background: #F4F5F6;
        }
        &-status {
            border-radius: 100%;
            width: 14px;
            height: 14px;
            margin-left: 10px;
            &_completed {
                background: #6EC87A;
            }
            &_current {
                background: #FFB300;
            }
            &_uncompleted {
                background: #DCE1E8;
            }
            &-icon {
                padding-left: 2px;
            }
        }
        &-next {
            &-icon {
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
</style>