<template>
	<div class="definition">
		<AppPanel
			class="definition__panel"
			:template="templateName"
			:clientName="clientName"
			:fileName="fileName"
			@reset="onAllReset"
            @onSetAuto="onSetAuto"
		/>
        <!-- <div class="definition__info">
            Обязательные поля: клиент, город, адрес, сторона, оператор, ФИО баера, себестоимость услуги (аренда, печать, монтаж) и ее юр. лица
        </div> -->
		<div class="definition__table-place">
			<AppTable
                ref="appTable"
				class="definition__table"
				primaryKey="id"
				:columns="columns"
				:rows="rows"
                @onScroll="onScroll"
			>
				<template v-slot:choose="scopeData">
					<BaseSelect
                        :canSearch="true"
						:value="scopeData.row.choose.selected"
						:options="scopeData.row.choose.options"
                        :isCustomPreview="getIsCustomPreview(scopeData)"
                        :previewColor="getPreviewColor(scopeData)"
                        :isFixed="true"
						defaultTitle="Игнорировать"
						position="left"
						style="width: 100%"
						@check="onSelect({rowData: scopeData.row, optionData: $event})"
					>
						<template #header>
							<span><b>Выберите значение</b></span>
						</template>
					</BaseSelect>
				</template>
			</AppTable>
		</div>
	</div>
</template>

<script>
import AppPanel from "./Panel"
import AppTable from "@/components/Table2/Table"
import BaseSelect from "@/components/Base/BaseSelect"

export default {
	name: "Definition",
	components: {
		AppPanel,
		AppTable,
		BaseSelect
	},
	props: {
		fileName: {
			type: String,
			default: ""
		},
		templateName: {
			type: String,
			default: ""
		},
		clientName: {
			type: String,
			default: ""
		},
		columns: {
			type: Array,
			default: () => []
		},
		rows: {
			type: Array,
			default: () => []
		},
	},
	methods: {
        getIsCustomPreview(scopeData = {}) {
            const {row = {}} = scopeData;
            const {choose = {}} = row;
            const {isCustomPreview = false} = choose;
            return Boolean(isCustomPreview);
        },
        getPreviewColor(scopeData = {}) {
            const {row = {}} = scopeData;
            const {choose = {}} = row;
            const {previewColor = ''} = choose;
            return String(previewColor);
        },
		onAllReset() {
			this.$emit('allReset')
		},
		onSelect(event) {
			this.$emit('check', event)
		},
        onSetAuto() {
            this.$emit('onSetAuto');
        },
        onScroll() {
            this.$refs.appTable.onCloseSelect();
        }
	}
}
</script>

<style lang="scss" scoped>
.definition {
	display: flex;
	flex-direction: column;
    &__panel {
        display: flex;
        justify-content: space-between;
    }
    &__info{
        padding: 20px 10px;
        font-weight: bold;
    }

	&__table-place {
		flex-grow: 1;
		margin-top: 30px;
		overflow: hidden;
	}

	&__table {
		height: 100%;
		// max-width: 100%;
		width: 100%;
		display: inline-block;
		padding-top: 34px!important;
	}
}
</style>
