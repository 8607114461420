<template>
	<div class="setting">
		<div class="setting-section">
			<div class="setting-section__title">Название шаблона<i>*</i></div>
			<div class="setting-section__content">
				<!-- <BaseButton class="button">Любое название шаблона</BaseButton> -->
				<BaseInput
					placeholder="Любое название шаблона"
					:value="templateName"
					view="primary"
					@input="$emit('changeTemplate', $event)"
				/>
			</div>
		</div>

<!--		<div class="setting-section">
			<div class="setting-section__title">Выберите оператора</div>
			<div class="setting-section__content">
				<BaseSelect
					class="select"
					defaultTitle="Оператор не выбран"
					:value="clientId"
					:options="clientList"
					@check="onCheckClient"
				>
					<template #header>
						<span><b>Выберите значение</b></span>
					</template>
				</BaseSelect>
			</div>
		</div>-->
	</div>
</template>

<script>
// import BaseButton from "@/components/Base/BaseButton"
// import BaseSelect from "@/components/Base/BaseSelect"
// import AttachFile from "@/components/AttachFile"
import BaseInput from "@/components/Base/BaseTextInput"

export default {
	name: "AppAttachDate",
	components: {
		// BaseButton,
		// BaseSelect,
		// AttachFile,
		BaseInput
	},
	props: {
		clientId: {
			type: [String, Number],
		},
		templateName: {
			type: String,
			default: ""
		},
		fileData: {
			type: File,
			default: null
		},
		clientList: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		onCheckClient(optionData) {
			this.$emit('checkClient', optionData)
		}
	}
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;

.setting {
	.setting-section {

		&:not(:first-child) {
			margin-top: 20px;
		}

		&__title {
			font-weight: 500;

			i { color: $danger-color; }
		}
		&__content {
			margin-top: 8px;
		}
	}

	.button {
		width: 100%;
		text-align: left;
	}
	.select {
		width: 100%;
		height: 36px;
	}
	.set-file {
		// height: 276px;
	}
}
</style>

<style lang="scss">
.setting {
	&-section {
		.select .btn{
			height: 100%;
		}
	}

	.d-wrap__content-wrapper {
		height: 100%;
	}
}
</style>
